







































































import { Mixin_list } from "@/mixin";
import { api_goodsCategory, goodsCategory } from "@/http/goodsCategory";
import { Component } from "vue-property-decorator";
import Page from "@/components/page/index.vue";
import Table from "@/components/table/index.vue";

@Component({
  components: {
    Page,
    Table,
  },
})
export default class extends Mixin_list<goodsCategory>(
  api_goodsCategory.get_list
) {
  filter = {};

  columns = [
    {
      label: "编号",
      //   sortable: true,
      prop: "id",
      width: 80,
    },
    {
      label: "分类名称",
      prop: "name",
    },
    {
      label: "分类描述",
      prop: "desc",
    },
    {
      label: "备注",
      prop: "remark",
    },
    {
      label: "创建时间",
      prop: "create_time",
    },
    {
      label: "操作",
      prop: "",
      width: 300,
      slot: "operation",
    },
  ];

  async handleClick(type: string, row: any) {
    switch (type) {
      case "status":
        await api_goodsCategory.edit({
          ...row,
          status: row.status === 0 ? 1 : 0,
        });
        this.get_list();
        return;
    }
  }

  click_cell(row: goodsCategory) {
    return this.$router.push(`/category/goodsCategory/edit/?id=${row.id}`);
  }

  async del(id: number) {
    await api_goodsCategory.del_one(id);
    this.get_list();
  }
}
